.assignment-item {
    --back-color: var(--light-back-color);
    align-items: center;
    background-color: var(--back-color);
    /* border space for pinned item */
    border: 2px solid var(--back-color);
    border-radius: var(--basic-border-radius);
    color: var(--dark-font-color);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 3px 10px;
    transition: all var(--basic-transition-duration);
    user-select: none;
}

.assignment-item:active {
    --back-color: var(--main-back-color-2);
}

.assignment-item > div {
    align-items: center;
    display: flex;
}

.assignment-item-operation {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
    border-radius: 2px;
    height: 25px;
    width: 25px;
}

.assignment-item-operation-make-complete {
    background-image: url(/public/lib/assignment_list/complete.svg);
    border: solid 2px var(--sub-back-color);
    height: 21px;
    width: 21px;
}

.assignment-item-operation-make-incomplete {
    background-color: var(--warning-color);
    background-image: url(/public/lib/assignment_list/incomplete.svg);
}

.assignment-item-content {
    margin-left: 8px;
}

.assignment-item-course {
    font-size: 110%;
    font-weight: bold;
}

.assignment-item-deadline {
    color: var(--accent-color);
    font-size: 70%;
}

.assignment-item-button-item {
    background-color: gray;
    border-radius: 11px;
    /* Hide button element until implementation of assignment button. */
    display: none;
    height: 22px;
    margin-right: 5px;
    width: 22px;
}

.assignment-item-button-item:last-of-type {
    margin-right: 0;
}
