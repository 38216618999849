.Settings {
    scrollbar-width: none;
}

.Settings::-webkit-scrollbar {
    display: none;
}

.settings {
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.settings-group {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100vw;
}

.settings-group-title {
    display: inline-flex;
    font-size: 85%;
    margin: 15px 0 8px;
    user-select: none;
}

.settings-group-items {
    align-items: center;
    display: flex;
    flex-direction: column;
    user-select: none;
    width: 100vw;
}
