.settings-item {
    align-items: baseline;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    transition: all var(--basic-transition-duration);
    padding: 8px 5px 5px;
    user-select: none;
    width: calc(90% - 10px);
}

.settings-item:active {
    background-color: var(--main-back-color-2);
}

.settings-item:first-of-type {
    border-top: 1px solid var(--border-color);
    padding-top: 5px;
}

.settings-item-name {}

.settings-item-value {
    color: var(--main-back-color-2);
    font-size: 85%;
}

.settings-item-value-focused {
    color: var(--light-font-color);
    font-size: 85%;
}

.settings-item-value-red {
    color: #bc3c3c;
}
