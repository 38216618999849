.Header {
    background-color: var(--main-back-color);
    height: var(--header-height);
    width: 100%;
}

.header-content {
    align-items: center;
    background-color: var(--sub-back-color);
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    top: calc(var(--header-height) * -1);
    transition: all var(--page-switch-transition-duration) var(--basic-transition-cubic-bezier);
    user-select: none;
    width: 100%;
}

.header-text {
    font-size: 110%;
    position: relative;
    top: -.1em;
}
