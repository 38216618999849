.Login {
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.login-service {
    align-items: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15vh;
}

.login-service-catchphrase {
    font-size: 110%;
    margin-bottom: 2px;
}

.login-service-name {
    font-size: 150%;
}

.login {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 0 calc(var(--layout-margin) * 2);
    width: 100vw;
}

.login-item {
    align-items: center;
    background-color: #ffffff;
    border-radius: 3px;
    color: #333333;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-bottom: var(--layout-margin);
    padding: 6px 10px;
    width: calc(85vw - (10px * 2));
}

.login-item:last-of-type {
    margin-bottom: 0;
}

.login-item-icon {
    margin-right: 8px;
}

.login-item-title {
    user-select: none;
}
