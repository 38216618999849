@font-face {
    font-family: 'HP Simplified';
    src: url('/public/fonts/HP Simplified.ttf');
}

@font-face {
    font-family: 'HP Simplified Light';
    src: url('/public/fonts/HP Simplified Light.ttf');
}

:root {
    --font-family: 'HP Simplified', 'sans-serif';

    --main-back-color: #60c45e;
    --main-back-color-2: #d2f3d2;
    --sub-back-color: #68a353;
    --light-back-color: #ffffff;

    --accent-color: #f1d44e;

    --warning-color: #e00d42;
    --warning-color-2: #f8e8ea;

    --border-color: #c1ebc1;

    --light-font-color: #ffffff;
    --dark-font-color: #333333;
    --dark-font-color-2: #888888;

    --layout-margin: 13px;
    --horizontal-switcher-margin: 20px;
    --display-order-bottom-margin: 5px;

    --header-height: 40px;
    --body-height: calc(100vh - var(--header-height) - var(--bottom-menu-height));
    --bottom-menu-height: 45px;
    --bottom-menu-item-size: 20px;

    --basic-border-radius: 10px;

    --basic-transition-cubic-bezier: cubic-bezier(0.075, 0.82, 0.165, 1);
    --basic-transition-duration: 80ms;
    --page-switch-transition-duration: 300ms;
}

body {
    color: var(--light-font-color);
    font-family: var(--font-family);
    margin: 0;
}

.body-component {
    background-color: var(--main-back-color);
    display: flex;
    height: var(--body-height);
    /* set the initial position for transition effect */
    left: 0;
    overflow-x: hidden;
    position: absolute;
    transition: left var(--page-switch-transition-duration) var(--basic-transition-cubic-bezier), width var(--page-switch-transition-duration) var(--basic-transition-cubic-bezier);
    width: 100%;
}

.body-subcomponent {
    display: flex;
}
