.AssignmentPreview {
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: none;
}

.AssignmentPreview::-webkit-scrollbar {
    display: none;
}

.preview {
    display: flex;
    justify-content: center;
    margin-bottom: var(--horizontal-switcher-margin);
    width: 100vw;
}

.preview > div {
    display: flex;
    flex-direction: column;
}

.preview-left {
    margin-left: var(--layout-margin);
    word-break: break-word;
    width: 100vw;
}

.preview-description, .preview-note {
    background-color: var(--light-back-color);
    border-radius: var(--basic-border-radius);
    font-size: 90%;
    padding: 8px 15px;
}

.preview-description {
    color: var(--dark-font-color);
    font-size: 90%;
    margin-bottom: 10px;
    padding: 8px 15px;
}

.preview-note {
    color: var(--dark-font-color-2);
}

.preview-management {
    margin-left: var(--basic-border-radius);
    margin-top: calc(var(--layout-margin) / 2);
    font-size: 80%;
}

.preview-detail {
    align-items: center;
    background-color: var(--light-back-color);
    border-radius: var(--basic-border-radius);
    color: var(--dark-font-color);
    display: flex;
    flex-direction: column;
    margin: 0 var(--layout-margin);
    padding: 0 10px;
}

.preview-detail-deadline {
    color: var(--accent-color);
    font-weight: bold;
    margin: 8px 0;
    font-size: 80%;
}

.preview-detail-group {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.preview-detail-title {
    font-size: 80%;
    font-weight: bold;
}

.preview-detail-content {
    font-size: 130%;
    text-align: center;
}

.preview-detail-content > a {
    color: var(--sub-back-color);
}

.preview-detail-arrow {
    background-image: url(/public/lib/assignment_preview/arrow_down.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 30px;
    margin-bottom: 8px;
    width: 12px;
}
