.horizontal-switcher {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: var(--horizontal-switcher-margin) 0;
    width: 100vw;
}

.switcher-top {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.switcher-button {
    background-color: #aaaaaa;
    border-radius: 15px;
    /* Hide button element until implementation of switcher button. */
    display: none;
    height: 30px;
    width: 30px;
}

.switcher-title {
    font-size: 130%;
    margin: 0 30px;
    user-select: none;
}

.switcher-description {
    font-size: 90%;
    user-select: none;
}
