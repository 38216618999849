.BottomMenu {
    background-color: var(--main-back-color);
    display: flex;
    flex-direction: column;
    height: var(--bottom-menu-height);
    overflow: hidden;
    width: 100%;
}

.bottom-menu {
    background-color: var(--sub-back-color);
    position: relative;
    top: var(--bottom-menu-height);
    transition: all var(--page-switch-transition-duration) var(--basic-transition-cubic-bezier);
}

.bottom-menu-content {
    align-items: center;
    display: flex;
    height: calc(var(--bottom-menu-height) - 3px);
    justify-content: space-around;
    width: 100%;
}

.bottom-menu-content-item {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
    height: var(--bottom-menu-item-size);
    width: var(--bottom-menu-item-size);
}

.bottom-menu-content-bar {
    background-color: var(--light-back-color);
    border-radius: 1.5px;
    height: 3px;
    margin-bottom: 3px;
    position: relative;
    transition: all 200ms var(--basic-transition-cubic-bezier);
    width: var(--bottom-menu-item-size);
}
