.AssignmentList {
    align-items: center;
    flex-direction: column;
}

.display-order {
    margin: var(--layout-margin) 0 calc(var(--layout-margin) - var(--display-order-bottom-margin)) 0;
    width: 90vw;
}

.display-order-item {
    background-color: var(--main-back-color-2);
    border: 2px solid var(--border-color);
    border-radius: 100vh;
    color: var(--dark-font-color-2);
    cursor: pointer;
    display: inline-block;
    font-size: 80%;
    margin-right: 5px;
    padding: 4px 10px;
    transition: all var(--basic-transition-duration);
    user-select: none;
    margin-bottom: var(--display-order-bottom-margin);
}

.display-order-item:last-of-type {
    margin: 0;
}

.display-order-item-selected {
    background-color: var(--light-back-color);
    color: var(--dark-font-color);
}

/* 
.display-order-item:active {
    background-color: var(--main-back-color);
    border-color: var(--sub-back-color);
    color: var(--light-font-color);
}
*/

.division-line {
    border: 1px solid var(--border-color);
    margin: 0;
    width: 90vw;
}

.assignment-list {
    align-items: center;
    display: flex;
    flex-direction: column;
    -ms-overflow-style: none;
    overflow-y: scroll;
    scrollbar-width: none;
    /* change width to justify the scrollbar position */
    width: 90vw;
}

.assignment-list::-webkit-scrollbar {
    display: none;
}

.assignment-list-message {
    margin: var(--layout-margin);
}
